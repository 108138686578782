import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';

const colors = [
  { name: 'Red', value: '255,72,72,1' },
  { name: 'Orange', value: '221,184,119,1' },
  { name: 'Green', value: '148,195,100,1' },
  { name: 'Blue', value: '118,219,228,1' },
  { name: 'Purple', value: '196,65,239,1' },
  { name: 'Yellow', value: '233,211,106,1' },
  { name: 'Pink', value: '225,136,136,1' },
  { name: 'Default', value: '250,250,248,1' }
];

export default function CanvasMenu({ onClose }) {
  const { changeSelectedNodesBackgroundColor } = useMlCanvas(); // Access the context function

  const handleColorChange = (color) => {
    changeSelectedNodesBackgroundColor(color); // Apply the selected color to the nodes
    onClose(); // Close the menu after a color is selected
  };

  return (
    
      <MenuList dense sx={{ width: 200 }}>
        {colors.map((color) => (
          <MenuItem key={color.name} onClick={() => handleColorChange(color.value)}>
            <ListItemText>{color.name}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
   
  );
}
