import React, { createContext, useState, useContext, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ObjectId } from 'bson';

import {
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    getRectOfNodes
} from 'reactflow';

import {
    getUserId,
    createNode,
    nodesPut,
    getRootCanvasTitlesAndIds,
    getNodesTitlesAndIds,
    getCanvasById,
    nodesDelete,
} from './MlNode';
import { createNewNode } from './MlNodeUtils';
import { AppendJSONFlow } from './MlAppendJsonFlow';
import MlSlideModal from '../components/Mainlist/Dialogs/MlSlideModal';
import { generateOutlineText } from './textStructureUtils';
import { getDisplayList } from './jsonStructureUtils';

import audioManager from './audioUtils';

export const MlCanvasContext = createContext();

const initialNodes = [];  // Define initial values
const initialEdges = [];  // Define initial values
const S3_URL_BASE = process.env.S3_URL_BASE;

export const useMlCanvas = () => {
    const context = useContext(MlCanvasContext);
    if (!context) {
        throw new Error('useMlCanvas must be used within a MlCanvasProvider');
    }
    return context;
};



export const MlCanvasProvider = ({ children }) => {
    const [backgroundColor, setBackgroundColor] = useState('rgb(235,235,235)');
    const [historyCanvasList, setHistoryCanvasList] = useState([]);
    const [statsCanvasList, setStatsCanvasList] = useState([]);
    const [rfInstance, setRfInstance] = useState(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [needToSave, setNeedToSave] = useState(false);
    const [customNodeData, setCustomNodeData] = useState({});
    const [rootCanvasList, setRootCanvasList] = useState([]);
    const [deletedCanvasId, setDeletedCanvasId] = useState(null);
    const [shouldSave, setShouldSave] = useState(true);
    const [mlNode, setMlNode] = useState(null);
    const [selectedNodeIds, setSelectedNodeIds] = useState([]);
    const [isInitialViewFitDone, setIsInitialViewFitDone] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [slide, setSlide] = useState(null);
    const [slideType, setSlideType] = useState(null);
    const [modals, setModals] = useState([]);
    const [modalKey, setModalKey] = useState(0);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [currentNode, setCurrentNode] = useState([]);
    const [isPaused, setIsPaused] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const shouldStopRef = useRef(true); // Using ref to hold the shouldStop value

    const refreshCanvas = useCallback(() => {
        // First, store the current nodes and edges
        const currentNodes = [...nodes];
        const currentEdges = [...edges];
    
        // Clear the current nodes and edges
        setNodes([]);
        setEdges([]);
    
        // Reset them after a short delay to ensure the clear has been registered
        setTimeout(() => {
            setNodes(currentNodes);
            setEdges(currentEdges);
        }, 0); // A short timeout to ensure that the state update is applied
    }, [nodes, edges, setNodes, setEdges]);

    const createTextOutline = useCallback(() => {
        return generateOutlineText(nodes, edges);
    }, [nodes, edges]);

    const quanyxStorage = 'https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com';

    const showSlide = useCallback((type, slide = null, localData) => {
        let theSlide;

        if (localData) {
            if (type === 'qdraft') {
                theSlide = localData[slide];
                setCurrentNode(localData);
            }
        } else if (type === 'qdraft') {
            theSlide = currentNode[slide];
        } else {
            theSlide = slide ? `${quanyxStorage}${slide}` : null;
        }

        const newModal = { id: uuidv4(), slideType: type, slide: theSlide };
        setModals(currentModals => [...currentModals, newModal]);
    }, [quanyxStorage, currentNode, setCurrentNode]);

    const removeModal = useCallback((modalId) => {
        setModals(currentModals => currentModals.filter(modal => modal.id !== modalId));
    }, []);

    const closeAllModals = useCallback(() => {
        setModals([]);
    }, []);

    const [currentDbNodeId, setCurrentDbNodeId] = useState(null);
    const getCurrentDbNodeId = () => currentDbNodeId;

    const [cursorStyle, setCursorStyle] = useState('default');

    const updateContainerSize = useCallback((width, height) => {
        setContainerSize({ width, height });
    }, []);

    const setPointer = (style) => {
        setCursorStyle(style);
    };

    const getPointer = () => cursorStyle;

    const [isDeleting, setIsDeleting] = useState(false);

    const initialAnimation = {
        targets: []
    };
    const [animation, setAnimation] = useState(initialAnimation);
    const [currentTargetIndex, setCurrentTargetIndex] = useState(0);

    const handleAnimateZooms = useCallback(() => {
    }, [selectedNodeIds]);

    const copySelectedNodesToClipboard = useCallback(() => {
        if (!rfInstance) {
            console.error('React Flow instance is not available');
            return;
        }

        const selectedNodes = rfInstance.getNodes().filter(node => node.selected);

        if (selectedNodes.length === 0) {
            console.log('No nodes selected to copy');
            return;
        }

        const serializedNodes = JSON.stringify(selectedNodes.map(node => ({
            id: node.id,
            type: node.type,
            position: node.position,
            data: node.data,
        })));

        navigator.clipboard.writeText(serializedNodes).then(() => {
            console.log('Selected nodes copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy nodes to clipboard:', err);
        });
    }, [rfInstance]);


    // PLAY CANVAS

    const getBoundsOfNodes = useCallback((nodeIds) => {
        const selectedNodes = nodes.filter(node => nodeIds.includes(node.id));
        return getRectOfNodes(selectedNodes);
    }, [nodes]);

    const zoomToRect = useCallback((bounds, duration = 500, padding = 0) => {
        if (!rfInstance) {
            console.error("React Flow instance is not available");
            return;
        }
        rfInstance.fitBounds({
            x: bounds.x,
            y: bounds.y,
            width: bounds.width,
            height: bounds.height,
        }, { padding: padding, duration: duration });
    }, [rfInstance]);

    const zoomToNodesByIds = useCallback((nodeIds, duration = 500) => {
        const bounds = getBoundsOfNodes(nodeIds);
        if (bounds) {
            zoomToRect(bounds, duration);
        }
    }, [getBoundsOfNodes, zoomToRect]);

    const playCanvas = useCallback(async () => {
        setIsPlaying(true); // Set playing state to true when starting playback
        shouldStopRef.current = false; // Reset shouldStop state when starting playback

        const list = getDisplayList(nodes, edges);

        for (let index = 0; index < list.length; index++) {
            if (shouldStopRef.current) break; // Check if playback should be stopped

            const nodeId = list[index][0];
            zoomToNodesByIds([nodeId]);

            const currentNode = nodes.find(node => node.id === nodeId);

            if (currentNode && currentNode.data && currentNode.data.soundLink) {
                const soundUrl = quanyxStorage + currentNode.data.soundLink;
                audioManager.playSound(soundUrl);
                setIsAudioPlaying(true);

                await new Promise(resolve => {
                    const handleEnd = () => {
                        audioManager.currentSound.off('end', handleEnd);
                        resolve();
                    };

                    audioManager.currentSound.on('end', handleEnd);
                });
            } else {
                await new Promise(resolve => setTimeout(resolve, 2500));
            }
        }

        //fitView();
        setIsAudioPlaying(false);
        setIsPlaying(false); // Set playing state to false when playback finishes
    }, [nodes, edges, zoomToNodesByIds, quanyxStorage]);

    const stopCanvas = () => {
        shouldStopRef.current = true;
        if (audioManager.currentSound) {
            audioManager.stopSound();
        }
        setIsPlaying(false);
        setIsAudioPlaying(false);
    };

    useEffect(() => {
        return () => {
            if (audioManager.currentSound) {
                audioManager.currentSound.off('end');
                audioManager.stopSound();
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            if (audioManager.currentSound) {
                audioManager.currentSound.off('end');
                audioManager.stopSound();
            }
        };
    }, []);

    const addAnimationTarget = useCallback((position = 'end') => {
        if (!rfInstance || !mlNode) return;

        const selectedNodes = rfInstance.getNodes().filter(node => node.selected);
        if (selectedNodes.length === 0) return;

        const bounds = getRectOfNodes(selectedNodes);
        if (!bounds) {
            console.error("Failed to get bounds for selected nodes.");
            return;
        }

        const newTarget = {
            id: uuidv4(),
            name: `Target_${(mlNode.animation?.targets?.length ?? 0)}`,
            bounds,
            duration: 1000
        };

        if (!mlNode.animation) {
            mlNode.animation = { targets: [] };
        }

        let newTargets = [...mlNode.animation.targets];
        switch (position) {
            case 'before':
                if (currentTargetIndex >= 0 && newTargets.length > 0) {
                    newTargets.splice(currentTargetIndex, 0, newTarget);
                }
                break;
            case 'after':
                if (currentTargetIndex >= 0 && newTargets.length > 0) {
                    newTargets.splice(currentTargetIndex + 1, 0, newTarget);
                }
                break;
            case 'end':
            default:
                newTargets.push(newTarget);
                break;
        }

        setAnimation(prevAnimation => ({
            ...prevAnimation,
            targets: newTargets
        }));

        const updatedNode = { ...mlNode, animation: { ...mlNode.animation, targets: newTargets } };
        setMlNode(updatedNode);
    }, [rfInstance, mlNode, animation.targets, currentTargetIndex]);

    const setAnimationTargets = useCallback((newOrder) => {
        const reorderedTargets = newOrder.map(item => {
            return animation.targets.find(target => target.id === item.id);
        });

        setAnimation(prevAnimation => ({
            ...prevAnimation,
            targets: reorderedTargets
        }));

        const updatedNode = { ...mlNode, animation: { ...mlNode.animation, targets: reorderedTargets } };
        setMlNode(updatedNode);
    }, [animation.targets, setAnimation, mlNode, setMlNode]);

    const resetAnimation = useCallback(() => {
        setAnimation({
            targets: []
        });

        setCurrentTargetIndex(0);

        if (mlNode) {
            const updatedNode = { ...mlNode, animation: { targets: [] } };
            setMlNode(updatedNode);
        }
    }, [setAnimation, setCurrentTargetIndex, mlNode, setMlNode]);

    const updateCurrentAnimationTarget = useCallback(() => {
        if (!rfInstance || !mlNode || mlNode.animation?.targets?.length === 0) return;

        const selectedNodes = rfInstance.getNodes().filter(node => node.selected);
        if (selectedNodes.length === 0) return;

        const bounds = getRectOfNodes(selectedNodes);
        if (!bounds) {
            console.error("Failed to get bounds for selected nodes.");
            return;
        }

        if (currentTargetIndex < 0 || currentTargetIndex >= mlNode.animation.targets.length) return;

        const updatedTarget = {
            ...mlNode.animation.targets[currentTargetIndex],
            bounds,
        };

        const newTargets = [...mlNode.animation.targets];
        newTargets[currentTargetIndex] = updatedTarget;

        setAnimation(prevAnimation => ({
            ...prevAnimation,
            targets: newTargets
        }));

        const updatedNode = { ...mlNode, animation: { ...mlNode.animation, targets: newTargets } };
        setMlNode(updatedNode);
    }, [rfInstance, mlNode, animation.targets, currentTargetIndex]);

    const renameCurrentAnimationTarget = useCallback((newName) => {
        if (!mlNode || !animation.targets || animation.targets.length === 0) {
            console.error("No animation targets available or mlNode is not set.");
            return;
        }

        if (currentTargetIndex < 0 || currentTargetIndex >= animation.targets.length) {
            console.error("Invalid currentTargetIndex.");
            return;
        }

        const updatedTargets = [...animation.targets];
        updatedTargets[currentTargetIndex] = { ...updatedTargets[currentTargetIndex], name: newName };

        setAnimation(prevAnimation => ({
            ...prevAnimation,
            targets: updatedTargets
        }));

        const updatedNode = { ...mlNode, animation: { ...mlNode.animation, targets: updatedTargets } };
        setMlNode(updatedNode);
    }, [mlNode, animation.targets, setAnimation, setMlNode, currentTargetIndex]);

    const deleteCurrentAnimationTarget = useCallback(() => {
        if (!mlNode || !animation.targets || animation.targets.length === 0) {
            console.error("No animation targets available or mlNode is not set.");
            return;
        }

        const updatedTargets = animation.targets.filter((_, index) => index !== currentTargetIndex);

        setAnimation(prevAnimation => ({
            ...prevAnimation,
            targets: updatedTargets
        }));

        const updatedNode = { ...mlNode, animation: { ...mlNode.animation, targets: updatedTargets } };
        setMlNode(updatedNode);

        if (currentTargetIndex >= updatedTargets.length) {
            setCurrentTargetIndex(0);
        }
    }, [mlNode, animation.targets, currentTargetIndex]);

    function adjustViewWithOptions(boundingRect, option, padding = 0) {
        let adjustedBounds = { ...boundingRect };
        const viewportWidth = containerSize.width;
        const viewportHeight = containerSize.height;

        switch (option) {
            case 'fit':
                break;
            case 'top':
                const scaleFactor = boundingRect.width / containerSize.width;
                adjustedBounds.height = containerSize.height * scaleFactor;
                break;
            case 'left':
                break;
            case 'bottom':
                break;
        }

        rfInstance.fitBounds({
            x: adjustedBounds.x,
            y: adjustedBounds.y,
            width: adjustedBounds.width,
            height: adjustedBounds.height,
        }, { padding: padding, duration: 1000 });

        return adjustedBounds;
    }

    const getTargetProp = (propName, targetString) => {
        const propRegex = new RegExp(`${propName}\\s*:\\s*([^,}]+)`);
        const match = propRegex.exec(targetString);

        if (match) {
            let value = match[1].trim().replace(/^['"]|['"]$/g, '');
            return isNaN(Number(value)) ? value : Number(value);
        }

        return null;
    };

    const goTarget = useCallback((targetSpecifier) => {
        if (!rfInstance || !animation.targets || animation.targets.length === 0) {
            console.error("No animation targets available or React Flow instance is not set.");
            return;
        }

        let targetIndex = currentTargetIndex;

        switch (targetSpecifier) {
            case 'first':
                targetIndex = 0;
                break;
            case 'last':
                targetIndex = animation.targets.length - 1;
                break;
            case 'next':
                targetIndex = (currentTargetIndex + 1) % animation.targets.length;
                break;
            case 'previous':
                targetIndex = (currentTargetIndex - 1 + animation.targets.length) % animation.targets.length;
                break;
            default:
                if (typeof targetSpecifier === 'number') {
                    targetIndex = targetSpecifier;
                } else if (targetSpecifier !== undefined) {
                    console.error(`Invalid target specifier: ${targetSpecifier}`);
                    return;
                }
        }

        const target = animation.targets[targetIndex];
        if (!target) {
            console.error(`No target found at index: ${targetIndex}`);
            return;
        }

        const position = getTargetProp('position', target.name);
        const adjustY = getTargetProp('adjustY', target.name);
        const scaleFactor = getTargetProp('scale', target.name);
        const scale = 1;
        const adjustedBounds = adjustViewWithOptions(target.bounds, position, 0);

        rfInstance.fitBounds({
            x: adjustedBounds.x,
            y: adjustedBounds.y + adjustY,
            width: adjustedBounds.width * scale,
            height: adjustedBounds.height * scale,
        }, { padding: 0, duration: 500 });

        setCurrentTargetIndex(targetIndex);
    }, [rfInstance, animation.targets, currentTargetIndex, setCurrentTargetIndex]);

    const getAnimationTargets = useCallback(() => {
        return animation.targets;
    }, [animation.targets]);

    const getNumberOfAnimationTargets = useCallback(() => {
        return animation.targets.length;
    }, [animation.targets]);

    const getBackgroundColor = () => backgroundColor;
    const changeBackgroundColor = (newColor) => setBackgroundColor(newColor);

    const updateCanvasNameInList = useCallback((canvasId, newName) => {
        const updatedList = rootCanvasList.map(canvas => {
            if (String(canvas._id).trim() === String(canvasId).trim()) {
                return { ...canvas, title: newName };
            }
            return canvas;
        });
        setRootCanvasList(updatedList);
    }, [rootCanvasList]);

    const getSortedStatsCanvasList = useCallback(() => {
        return statsCanvasList
            .sort((a, b) => b.count - a.count || b.lastUsed - a.lastUsed);
    }, [statsCanvasList]);

    const CreateProjectFromJSON = (jsonData) => {
        if (!jsonData) {
            return "Invalid input, Phil. Please provide valid JSON data.";
        }

        AppendJSONFlow(jsonData, nodes, edges);

        return "Project created successfully.";
    }

    const getHistoryCanvasList = useCallback(() => {
        return historyCanvasList;
    }, [historyCanvasList]);

    const saveToDatabase = useCallback(async (nodeToSave = null) => {
        const node = nodeToSave || mlNode;
        const currentUserId = getUserId();

        if (rfInstance && node && shouldSave) {
            if (node._id === deletedCanvasId) {
                return;
            }

            // Check if the userId of the node matches the current userId
            if (node.userId !== currentUserId) {
                console.log("User ID mismatch. Node not saved.");
                return;
            }

            const flowObject = rfInstance.toObject();
            const updatedNode = { ...node, canvas: flowObject, type: 1 };

            nodesPut([updatedNode]);
        }
    }, [mlNode, rfInstance, deletedCanvasId, shouldSave]);

    const createNewCanvas = useCallback(async (title = "New Canvas") => {
        const userId = getUserId();
        const newCanvasData = {
            title,
            canvas: { nodes: [], edges: [] },
            type: 1,
            userId
        };

        const newMlNode = await createNode(newCanvasData);

        if (newMlNode) {
            await saveToDatabase();
            setMlNode(newMlNode);
            setNodes([]);
            setEdges([]);
            await saveToDatabase();
        }
    }, [saveToDatabase, setEdges, setNodes]);

    const createCanvas = useCallback(async (title = "New Canvas") => {
        const userId = getUserId();
        const newCanvasData = {
            title,
            canvas: { nodes: [], edges: [] },
            type: 1,
            userId
        };

        const newMlNode = await createNode(newCanvasData);

        if (newMlNode) {
            nodesPut([newMlNode]);

            return newMlNode._id;
        }

        return null;
    }, []);

    const fetchRootCanvasList = async () => {
        const userId = getUserId();
        const list = await getRootCanvasTitlesAndIds(userId);
        setRootCanvasList(list);
    };

    const getRootCanvasList = async () => {
        const userId = getUserId();
        const list = await getRootCanvasTitlesAndIds(userId);
        return list;
    };

    const getTemplateList = async () => {
        const userId = getUserId();
        const list = await getRootCanvasTitlesAndIds(userId);
        return list;
    };

    const getNodeList = async (type, identity) => {
        const list = await getNodesTitlesAndIds(type, identity);
        return list;
    };

    useEffect(() => {
        fetchRootCanvasList();
    }, []);

    const addToStats = useCallback((canvasId, canvasTitle) => {
        setStatsCanvasList(prevStats => {
            const now = new Date().getTime();
            let isNewCanvas = true;

            const stringCanvasId = String(canvasId);

            const updatedStats = prevStats.map(canvas => {
                if (String(canvas._id) === stringCanvasId) {
                    isNewCanvas = false;
                    return { ...canvas, count: canvas.count + 1, lastUsed: now };
                }
                return canvas;
            });

            if (isNewCanvas) {
                updatedStats.push({ _id: stringCanvasId, title: canvasTitle, count: 1, lastUsed: now });
            }

            updatedStats.sort((a, b) => b.count - a.count || b.lastUsed - a.lastUsed);

            return updatedStats;
        });
    }, []);

    useEffect(() => {
        if (!isInitialViewFitDone && rfInstance) {
            if (animation.targets && animation.targets.length > 0) {
                goTarget(0);
            } else {
                rfInstance.fitView({ padding: 0.2 });
            }
            setIsInitialViewFitDone(true);
        }
    }, [animation.targets, rfInstance, goTarget, isInitialViewFitDone]);

    const loadCanvas = useCallback(async (canvasId) => {
        const canvas = await getCanvasById(canvasId);
        if (canvas) {
            const { nodes, edges } = canvas.canvas;

            let backgroundColorFound = false;
            let foundColor = "rgb(235,235,235)";

            for (const node of nodes) {
                if (node.data && node.data.BackgroundColor) {
                    backgroundColorFound = true;
                    foundColor = node.data.BackgroundColor;
                    break;
                }
            }
            console.log('NODES', nodes)
            console.log('EDGES', edges)
            setMlNode(canvas);
            setNodes(nodes);
            setEdges(edges);

            setAnimation({ targets: canvas.animation?.targets || [] });
            setIsInitialViewFitDone(false);
            addToStats(canvas._id, canvas.title);
        }
    }, [setMlNode, setNodes, setEdges, rfInstance, changeBackgroundColor]);

    const fitView = useCallback((duration = 500) => {
        if (rfInstance) {
            rfInstance.fitView({ padding: 0.2, duration: duration });
        }
    }, [rfInstance]);

    const loadPublicCanvas = useCallback(async (canvasId) => {
        try {
            const response = await fetch(`https://data.mongodb-api.com/app/mainlistapp-kqoki/endpoint/fetchPublicCanvas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ canvasId: canvasId }),
            });

            if (!response.ok) {
                throw new Error(`Error fetching canvas: ${response.status}`);
            }

            const canvas = await response.json();

            if (canvas) {
                const { nodes, edges } = canvas.canvas;

                let backgroundColorFound = false;
                let foundColor = "rgb(235,235,235)";

                for (const node of nodes) {
                    if (node.data && node.data.BackgroundColor) {
                        backgroundColorFound = true;
                        foundColor = node.data.BackgroundColor;
                        break;
                    }
                }
                setMlNode(canvas);
                setNodes(nodes);
                setEdges(edges);

                setAnimation({ targets: canvas.animation?.targets || [] });
                setIsInitialViewFitDone(false);
                addToStats(canvas._id, canvas.title);
            }
        } catch (error) {
            console.error('Error loading canvas:', error);
        }
    }, [setMlNode, setNodes, setEdges, rfInstance, changeBackgroundColor]);

    const changeSelectedNodesBackgroundColor = useCallback((newColor) => {
        if (!rfInstance) {
            console.error('React Flow instance is not available');
            return;
        }
    
        // Get the selected nodes
        const updatedNodes = nodes.map((node) => {
            if (node.selected) {
                // Update the background color in the node's data
                return {
                    ...node,
                    data: {
                        ...node.data,
                        backgroundColor: newColor
                    }
                };
            }
            return node; // Keep the node as is if it's not selected
        });
    
        // Update the state with the modified nodes
        setNodes(updatedNodes);
    }, [nodes, rfInstance, setNodes]);

    const deleteCurrentCanvas = useCallback(async () => {
        if (mlNode) {
            setIsDeleting(true);
            const query = { _id: mlNode._id };
            const deletedCount = await nodesDelete(query);

            if (deletedCount > 0) {
                setDeletedCanvasId(mlNode._id);
                const updatedRootCanvasList = rootCanvasList.filter(canvas => canvas._id !== mlNode._id);
                setRootCanvasList(updatedRootCanvasList);

                if (updatedRootCanvasList.length > 0) {
                    loadCanvas(updatedRootCanvasList[0]._id);
                } else {
                    createNewCanvas();
                }
                fetchRootCanvasList();
            }
        }
    }, [mlNode, rootCanvasList, loadCanvas, createNewCanvas]);

    const cutSelectedElements = (callback) => {
        if (!rfInstance) {
            console.error('React Flow instance is not available');
            return;
        }

        const selectedNodes = rfInstance.getNodes().filter(node => node.selected);
        const selectedEdges = rfInstance.getEdges().filter(edge => edge.selected);

        if (callback && typeof callback === 'function') {
            callback(selectedNodes, selectedEdges);
        }

        const remainingNodes = nodes.filter(node => !selectedNodes.some(n => n.id === node.id));
        const remainingEdges = edges.filter(edge => !selectedEdges.some(e => e.id === edge.id));

        setNodes(remainingNodes);
        setEdges(remainingEdges);
    };

    const pasteElements = (clipboardNodes, clipboardEdges, xOffset, yOffset) => {
        // Apply offset to each node's position, calculating offset dynamically if not provided
        const offsetNodes = clipboardNodes.map(node => {
            const calculatedXOffset = xOffset !== undefined ? xOffset : 0; // You can adjust this as needed
            const calculatedYOffset = yOffset !== undefined ? yOffset : (node.height || 0) + 5;
    
            return {
                ...node,
                position: {
                    x: node.position.x + calculatedXOffset,
                    y: node.position.y + calculatedYOffset,
                },
                positionAbsolute: {
                    x: node.positionAbsolute.x + calculatedXOffset,
                    y: node.positionAbsolute.y + calculatedYOffset,
                },
            };
        });
    
        setNodes(prevNodes => [...prevNodes, ...offsetNodes]);
        setEdges(prevEdges => [...prevEdges, ...clipboardEdges]);
    };
    

    const copySelectedElements = (callback) => {
        if (!rfInstance) {
            console.error('React Flow instance is not available');
            return;
        }

        const selectedNodes = rfInstance.getNodes().filter(node => node.selected);
        const selectedEdges = rfInstance.getEdges().filter(edge => edge.selected);

        const nodeIdMap = new Map();

        const newNodes = selectedNodes.map(node => {
            const newId = `node_${new ObjectId()}`;
            const currentDate = new Date();

            nodeIdMap.set(node.id, newId);

            return {
                ...node,
                id: newId,
                data: {
                    ...node.data,
                    _id: newId,
                    createdAt: currentDate,
                    updatedAt: currentDate,
                }
            };
        });

        const newEdges = selectedEdges.map(edge => {
            const newEdge = { ...edge, id: `edge_${new ObjectId()}` };
            newEdge.source = nodeIdMap.get(newEdge.source) || newEdge.source;
            newEdge.target = nodeIdMap.get(newEdge.target) || newEdge.target;
            return newEdge;
        });

        if (callback && typeof callback === 'function') {
            callback(newNodes, newEdges);
        }
    };

    const findNewNodeId = (originalId, originalNodes, newNodes) => {
        const originalNode = originalNodes.find(node => node.id === originalId);
        if (originalNode) {
            const index = originalNodes.indexOf(originalNode);
            return newNodes[index].id;
        }
        return originalId;
    };

    return (
        <MlCanvasContext.Provider
            value={{
                mlNode,
                setMlNode,
                rfInstance,
                setRfInstance,
                nodes,
                setNodes,
                onNodesChange,
                edges,
                setEdges,
                onEdgesChange,
                needToSave,
                setNeedToSave,
                customNodeData,
                setCustomNodeData,
                saveToDatabase,
                rootCanvasList,
                loadCanvas,
                loadPublicCanvas,
                createNewCanvas,
                deleteCurrentCanvas,
                updateCanvasNameInList,
                copySelectedElements,
                pasteElements,
                cutSelectedElements,
                getHistoryCanvasList,
                getSortedStatsCanvasList,
                getRootCanvasList,
                createCanvas,
                getBackgroundColor,
                changeBackgroundColor,
                handleAnimateZooms,
                addAnimationTarget,
                deleteCurrentAnimationTarget,
                goTarget,
                getAnimationTargets,
                getNumberOfAnimationTargets,
                resetAnimation,
                setAnimationTargets,
                setPointer,
                getPointer,
                fitView,
                updateCurrentAnimationTarget,
                renameCurrentAnimationTarget,
                quanyxStorage,
                updateContainerSize,
                containerSize,
                setCurrentDbNodeId,
                getCurrentDbNodeId,
                getNodeList,
                showSlide,
                closeAllModals,
                setCurrentNode,
                createTextOutline,
                playCanvas,
                stopCanvas,
                isPlaying,
                refreshCanvas,
                getUserId,
                changeSelectedNodesBackgroundColor
            }}
        >
            <ReactFlowProvider>
                {children}
            </ReactFlowProvider>

            {modals.map(modal => (
                <MlSlideModal
                    key={modal.id}
                    open={true}
                    onClose={() => removeModal(modal.id)}
                    slideType={modal.slideType}
                    slide={modal.slide}
                />
            ))}
        </MlCanvasContext.Provider>
    );
};
